import React, { useState, useEffect } from 'react';
import './cabina.css';
import Banner from './Componentes/banner'
import MP from "./Graficos/MP"
import MPM from "./Graficos/MPM"
import G from "./Graficos/G"
import GM from "./Graficos/GM"
import A from "./Graficos/A"
import AM from "./Graficos/AM"
//import cerrar from './Imagenes/cerrar.png'
import cerrar from './Imagenes/home_50dp_000000_FILL0_wght700_GRAD0_opsz48.svg'

import backk from './Imagenes/arrow_back_78dp_000000_FILL0_wght700_GRAD200_opsz20.svg'
import Swal from 'sweetalert2'
import moment from 'moment';
import Sidebar from './Componentes/sidebar'
import excel from './Imagenes/excel.png'
import CirclesToRhombusesSpinner from './Componentes/loading2'
import HalfCircleSpinner from './Componentes/loading'
//import { Snackbar, Button } from "@mui/material";

const ResumenCabina = () => {

  const Home = () => {

    window.location.href = "/inicio";
  
  };


//FIN CONSTANTES PARA ID'S DE PERFORADORAS O CAEX

//INICIO PARA SELECCIONES CHECKBOX Y SELECT LISTA DE EQUIPOS

const [selectedOption, setSelectedOption] = useState(null);

const handleChange = (value) => {
  setSelectedOption(value);
  setEnviado(false)
}

const [selectedOption2, setSelectedOption2] = useState('opcion 0');
const [selectedEquipo, setSelectedEquipo] = useState(null);
const [equipoexcel, setEquipoExcel] = useState(null);

//seleccionar el proyecto 1 ant, 8 centinella, 0 mostrar todo
const [useProyecto, setUseProyecto] = useState(1);  //1ant   8cen     0todos

const [selectedEquipoValue, setSelectedEquipoValue] = useState(""); 

const [cargando, setCargando] = useState(true);




const handleChange2 = (e) => {
  const selectedValue = e.target.value; 
  const selectedDatalogger = dataloggers.find(datalogger => 
    datalogger.attributes.MachineId === selectedValue
  ); // Buscar el datalogger correspondiente al valor

  // Si se encuentra el datalogger, obtener su label
  const selectedLabel = selectedDatalogger 
    ? selectedDatalogger.attributes.faena_ubicacion.data.attributes.FaenaUbicacion 
    : '';

    //console.log("handleChange2 value= "+selectedValue)
    setSelectedEquipoValue(selectedValue)
  setSelectedOption2(selectedValue);
  setEnviado(false);
  reiniciarEquipo(); // Reiniciar el estado del equipo antes de hacer la solicitud al API
  setSelectedEquipo(selectedValue);
  setEquipoExcel(selectedLabel); // Enviar el label al estado
// Si selectedOption está vacío, asignarlo a "option1", para que cuando se setee, entonces la pantalla entre por defecto en el grafico de material particulado
if (!selectedOption) {
  setSelectedOption("option1");
}

};

    const reiniciarEquipo = () => {
      setEquipo({});
    }
    
    const [selectedOption3, setSelectedOption3] = useState('op1');
    
    const handleChange3 = (event) => {
      setSelectedOption3(event.target.value);
      setEnviado(false)
    }

//FIN PARA SELECCIONES CHECKBOX Y SELECT LISTA DE EQUIPOS

// INICIO DE APIS PARA EQUIPOS PERFORADORES Y CAEX

const url_base = 'https://www.mine-360.com/api/cabina/sensor/consultcabina';

  const [equipo, setEquipo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url_base, { 
          method: "POST",
          body:JSON.stringify({"equipo": selectedEquipo}),
          headers: {"Content-Type": "application/json"}
        })
        const data = await response.json();

        setEquipo({
          PPM1: data.promedioPm1.toFixed(2),
          PPM10: data.promedioPm10.toFixed(2),
          PPM25: data.promedioPm25.toFixed(2),
          PCO2: data.promedioCo2.toFixed(2),
          PVOC: Math.round(data.promedioVoc),
          PCH2O: data.promedioCh2o.toFixed(2),
          PCO: data.promedioCo.toFixed(2),
          PO3: data.promediOo3.toFixed(2),
          PNO2: data.promedioNo2.toFixed(2),
          PTEMP: data.promedioTemp.toFixed(2),
          PHUM: data.promedioHum.toFixed(2),
          PRUIDO: data.promedioRuido.toFixed(2),

        });

        console.log(data.promedioRuido)
      } catch (error) {
        
      }
    };

    fetchData();
    
    const interval = setInterval(fetchData, 10000);
    
    return () => {
      clearInterval(interval);
    };
  }, [selectedEquipo]);


  const [dataloggers, setdataloggers] = useState([]);

  const datalogger = async () => {
    try {
    let response = await fetch("https://core.mine-360.com/api/dataloggers?pagination[limit]=1000&fields[0]=MachineName&fields[1]=MachineId&fields[2]=date&fields[3]=eye3&fields[4]=ActivarAlerta&fields[5]=report_details&fields[6]=report_date&fields[7]=description&fields[8]=createdAt&fields[9]=updatedAt&fields[10]=publishedAt&fields[11]=lat&fields[12]=lon&fields[13]=hdd&fields[14]=Uploaded_cero&fields[15]=servicios&fields[16]=EstadoAlerta&populate[faena]=*&populate[datalogger_vrsn]=*&populate[faena_ubicacion]=*&populate[proyecto]=*", { 
      method: "GET",
    });
    
    let data = await response.json();
    setdataloggers(data.data)

  } catch (error) {
    console.error("Error fetching faenas:", error);
  }
  }

  useEffect(() => {
    datalogger();
  }, []);



  //
  const [resumenEquipos, setresumenEquipos] = useState([]);
  const resumenDeEquipos = async () => {
    try {
      const proyecto = useProyecto; // proyecto en cuestion ANT es 1, todos es 0
   // let response = await fetch("http://localhost:8080/api/cabina/sensor/resumsencab", { 
  let response = await fetch("https://www.mine-360.com/api/cabina/sensor/resumsencab", { 
      
  method: "POST",
  headers: {
    "Content-Type": "application/json", // Asegurar que el cuerpo sea JSON
  },
  body: JSON.stringify({ proyecto }), // Enviar el argumento "proyecto" como cuerpo de la solicitud
});

// Comprobar si la respuesta es exitosa
    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.statusText}`);
    }

    
    let data = await response.json();
    setresumenEquipos(data)
    setCargando(false);

  } catch (error) {
    console.error("Error fetching resumen Equipos:", error);
  }
  }

  useEffect(() => {
    // Llama a la función de actualización inicialmente
    resumenDeEquipos();

    // Configura el intervalo para actualizar cada 30 segundos
    const intervalId = setInterval(resumenDeEquipos, 30000);

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);


    // Dividir los equipos en dos mitades
    const mitad = Math.ceil(resumenEquipos.length / 2);
    const primeraMitad = resumenEquipos.slice(0, mitad);
    const segundaMitad = resumenEquipos.slice(mitad);
//CONSTANTES PARA ID'S DE PERFORADORAS O CAEX (ESCRIBIR EL ID DEL EQUIPO PRESENTE EN LA BD)


const perforadora1 = 963;
const perforadora2 = 612;
const perforadora3 = 1;
const perforadora4 = 834;
const perforadora5 = 525;
const caex1 = 1;
const caex2 = 1;
const caex3 = 850;
const caex4 = 602;
const caex13 = 771; //estaba 1009
const caex21 = 792;
const caex23 = 673;
const caex29 = 1025;
const caex28 = 957;


// FIN DE APIS PARA EQUIPOS

// INICIO HORA

    const [date, setDate] = useState(new Date());
  
    useEffect(() => {
      const interval = setInterval(() => {
        setDate(new Date());
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);
  
    const formatDate = (date) => {
      return date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: '-03',
      });
    };
  
    const formatTime = (date) => {
      return date.toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: '-04',
        hour12: false,
      });
    };

// FIN HORA

// INICIO COLOR DE DIV

const getColorPM1 = (value) => { let backgroundColor, color;
  if (value >= 0 && value < 10) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 10 && value <= 25) { backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) { backgroundColor = 'white'; color = 'black';
  } else { backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPM25 = (value) => { let backgroundColor, color;
  if (value >= 0 && value < 25) { backgroundColor = 'green'; color = 'white';
  } else if (value >= 25 && value <= 50) { backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) { backgroundColor = 'white'; color = 'black';
  } else { backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPM10 = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 50) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 50 && value <= 100) { backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPCO2 = (value) => { let backgroundColor = ''; let color = '';
  if (value >= 0 && value < 500) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 500 && value <= 700) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPVOC = (value) => { let backgroundColor, color;
  if (value >= 0 && value <= 1) {backgroundColor = 'green'; color = 'white';
  } else if (value > 1 && value <= 2) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined ){backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPCH2O = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 1) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 1 && value <= 3) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPCO = (value) => { let backgroundColor, color;
  if (value >= 0 && value < 0.6) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 0.6 && value <= 1) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color};}

const getColorPO3 = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 0.1) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 0.1 && value <= 0.2) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined){backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPNO2 = (value) => { let backgroundColor = ''; let color = '';
  if (value >= 0 && value < 0.15) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 0.15 && value <= 0.2) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined ){backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPTEMP = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 25) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 25 && value <= 35) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPHUM = (value) => { let backgroundColor, color;
  if (value >= 0 && value < 50) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 50 && value <= 75) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

  const getColorPRUIDO = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 55) {backgroundColor = 'green'; color = 'white';
    } else if (value >= 55 && value <= 100) {backgroundColor = 'yellow'; color = 'black';
    } else if (value === null || value === undefined ){backgroundColor = 'white'; color = 'black';
    } else {backgroundColor = 'red'; color = 'white';}
    return { backgroundColor, color };}

// FIN COLOR DE DIV

// INICIO MODO MANUAL

 // Establecemos el rango de fechas predeterminado (últimos 14 días)
 const defaultFechaInicio = moment().subtract(14, 'days').format('YYYY-MM-DDTHH:mm');
 const defaultFechaFin = moment().format('YYYY-MM-DDTHH:mm');

const [fechaInicio, setFechaInicio] = useState(defaultFechaInicio);  
const [fechaFin, setFechaFin] = useState(defaultFechaFin);

const [conPromedio, setConPromedio] = useState(false);
const [rendercsv, setRendercsv] = useState(false);
//const [renderexcel, setRenderexcel] = useState(false);


const handleFechaInicioChange = (e) => {
  setFechaInicio(e.target.value);
  setEnviado(false)
}

const handleFechaFinChange = (e) => {
  setFechaFin(e.target.value);
  setEnviado(false)
}


const handlePromedioChange = () => {
  setConPromedio(!conPromedio);
};


//HORARIO VERANO U HORARIO INVIERNO. AGREGAR 4 HORAS O 3 HORAS DEPENDIENDO EL CASO.

const fechai = moment(fechaInicio, 'YYYY/MM/DD HH:mm:ss').add(4, 'hours').format('YYYY/MM/DD HH:mm:ss'); 
const fechaf = moment(fechaFin, 'YYYY/MM/DD HH:mm:ss').add(4, 'hours').format('YYYY/MM/DD HH:mm:ss');

const fecha1 = moment(fechaInicio, 'YYYY/MM/DD HH:mm:ss').add(4, 'hours').format('YYYY-MM-DD HH-mm'); 
const fecha2 = moment(fechaFin, 'YYYY/MM/DD HH:mm:ss').add(4, 'hours').format('YYYY-MM-DD HH-mm');

const [enviado, setEnviado] = useState(false);

const enviar = () => {
  if (fechai > fechaf) {
    Swal.fire({
      title: "La fecha de inicio no puede ser mayor a la fecha fin",
      confirmButtonText: 'OK',
      icon: "warning",
    });
  }
  if (fechai === "Invalid date" || fechaf === "Invalid date") {
    Swal.fire({
      title: "Seleccione un rango de fechas valido",
      confirmButtonText: 'OK',
      icon: "warning",
    });
  } else {
    if (fechai !== "Invalid date" && fechaf !== "Invalid date") {
      setEnviado(true);
  }
}
}

const [isDownloading, setIsDownloading] = useState(false); // Estado para saber si está descargando
const [buttonText, setButtonText] = useState('DESCARGAR CSV'); // Texto del botón

const handleCSVDownload = async () => {
  // Establecer el estado de descarga a "en proceso"


    // Validación de las fechas
    if (fechai > fechaf) {
      Swal.fire({
        title: "La fecha de inicio no puede ser mayor a la fecha fin",
        confirmButtonText: 'OK',
        icon: "warning",
      });
      return; // Si las fechas son inválidas, no hacer nada más
    }
  
    if (fechai === "Invalid date" || fechaf === "Invalid date") {
      Swal.fire({
        title: "Seleccione un rango de fechas válido",
        confirmButtonText: 'OK',
        icon: "warning",
      });
      return; // Si alguna de las fechas es inválida, no hacer nada más
    }



  setIsDownloading(true);
  setButtonText('Generando CSV...');  // Cambia el texto del botón para indicar que se está generando

  // Formatea las fechas usando Moment.js en el formato 'YYYY/MM/DD HH:mm:ss'
  const fechaInicioFormatted = moment(fechai).format('YYYY/MM/DD HH:mm:ss');
  const fechaFinFormatted = moment(fechaf).format('YYYY/MM/DD HH:mm:ss');

  const data = {
    "dateinicio": fechaInicioFormatted,
    "datefin": fechaFinFormatted,
    "conpromedio": conPromedio,
    "proyecto": useProyecto,
  };

  try {
    const response = await fetch('https://www.mine-360.com/api/cabina/sensor/genrepresum', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    // Si la respuesta es exitosa, procede con la descarga
    if (response.ok) {
      const blob = await response.blob();
      const fileName = `reporte_resumen_${conPromedio ? 'Promedio' : ''}_${fechaInicioFormatted}_a_${fechaFinFormatted}.csv`;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      
      // Restablece el estado del botón después de la descarga
      setIsDownloading(false);
      setButtonText('DESCARGAR CSV');
    } else {
      // En caso de error (por ejemplo, error 500)
      Swal.fire({
        title: "Sin datos en el rango de fechas seleccionado.",
        confirmButtonText: 'OK',
        icon: "error",
      });
      // Restablece el estado del botón incluso si hubo error
      setIsDownloading(false);
      setButtonText('DESCARGAR CSV');
    }
  } catch (error) {
    console.error('Error al generar el reporte:', error);

    // En caso de un error (por ejemplo, la API no responde)
    Swal.fire({
      title: "Ocurrió un error al generar el reporte.",
      confirmButtonText: 'OK',
      icon: "error",
    });
    // Restablece el estado del botón
    setIsDownloading(false);
    setButtonText('DESCARGAR CSV');
  }
};


const handleCSVDownloadAAA = async () => {
  setRendercsv(true);


   // Formatea las fechas usando Moment.js en el formato 'YYYY/MM/DD HH:mm:ss'
   const fechaInicioFormatted = moment(fechai).format('YYYY/MM/DD HH:mm:ss');
   const fechaFinFormatted = moment(fechaf).format('YYYY/MM/DD HH:mm:ss');


  const data = {
    "dateinicio": fechaInicioFormatted,
    "datefin": fechaFinFormatted,
    "conpromedio": conPromedio,
  };
  


try {
  




  const response = await fetch('https://www.mine-360.com/api/cabina/sensor/genrepresum', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (response.status === 500) {
    Swal.fire({
      title: "Sin datos en el rango de fechas seleccionado.",
      confirmButtonText: 'OK',
      icon: "error",
    });
    setRendercsv(false);
    return;
  }
  const fileName = `reporte_resumen_${conPromedio ? 'Promedio' : ''}_${fechaInicioFormatted}_a_${fechaFinFormatted}.csv`;

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  setRendercsv(false);
} catch (error) {
  console.log(error)
}


};



const [renderexcel, setRenderexcel] = useState(false); // Variable de estado para controlar si el response viene en blanco
const handleDownload = async () => {
  setRenderexcel(true); // Cambiar renderexcel a true antes de la petición fetch

  const data = {
    "equipo": selectedEquipo,
    "dateinicio": fechai,
    "datefin": fechaf
  };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  const response = await fetch('https://www.mine-360.com/api/cabina/sensor/reporteScabina', requestOptions);
  
  if (response.status === 500) { 
    Swal.fire({
    title: "Sin datos en el rango de fechas seleccionado.",
    confirmButtonText: 'OK',
    icon: "error",
  }); return; }

  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  // Cambia el nombre del archivo aquí
  const fileName = equipoexcel + " desde " + fecha1 + " hasta " + fecha2 + ".xlsx";
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  setRenderexcel(false);
}

// FIN MODO MANUAL

const mostrarResumen = !selectedOption && selectedOption2 === "opcion 0" && !selectedEquipo;

/*
const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");

  // Función para abrir el snackbar
  const openSnackBar = (message, actionText) => {
    setMessage(message);
    setAction(actionText);
    setOpen(true);
  };

  // Función para cerrar el snackbar
  const closeSnackBar = () => {
    setOpen(false);
  };
  */


return (

<div className="cabina">

  <Banner/>
  <Sidebar/>

  <div className="cabina-body">

    <div className="division1">

      <div className="division1-1">

        <label><input type="radio" value="option1" checked={selectedOption === 'option1'} onChange={() => handleChange('option1')} /> MATERIAL PARTICULADO</label>
        <label><input type="radio" value="option2" checked={selectedOption === 'option2'} onChange={() => handleChange('option2')} /> GASES</label>
        <label><input type="radio" value="option3" checked={selectedOption === 'option3'} onChange={() => handleChange('option3')} /> AMBIENTE</label>

      </div>

      <div className="division1-2">

      <div className={(selectedOption === "option1" || selectedOption === "option2" || selectedOption === "option3") && selectedOption2 === "opcion 0" ? "division1-2-1-2" : "division1-2-1"}>
  <div className="menu">
    <select onChange={handleChange2} value={selectedEquipoValue}>
      <option value="" disabled>SELECCIONE EQUIPO</option>
      {dataloggers
        .filter(datalogger => 
          datalogger.attributes.faena_ubicacion && 
          datalogger.attributes.faena_ubicacion.data && 
          datalogger.attributes.proyecto && 
          datalogger.attributes.proyecto.data && 
          datalogger.attributes.proyecto.data.id === useProyecto // Filtrar por proyecto ID 1 (Cabina ANT)
        )
        .sort((a, b) => {
          const locationA = a.attributes.faena_ubicacion.data.attributes.FaenaUbicacion.toLowerCase();
          const locationB = b.attributes.faena_ubicacion.data.attributes.FaenaUbicacion.toLowerCase();
          if (locationA < locationB) {
            return -1;
          }
          if (locationA > locationB) {
            return 1;
          }
          return 0;
        })
        .map(datalogger => (
          <option key={datalogger.id} value={datalogger.attributes.MachineId}>
            {datalogger.attributes.faena_ubicacion.data.attributes.FaenaUbicacion}
          </option>
        ))}
    </select>
  </div>
</div>

        <div className="division1-2-2">

        <div>Fecha: {formatDate(date)}</div>
        <div>Hora: {formatTime(date)}</div>

        </div>

        <div className="division1-2-3">

        <img 
  src={cerrar} 
  alt="regresar" 
  height="40px" 
  width="40px" 
  onClick={Home} 
  style={{
    backgroundColor: 'rgba(50, 50, 50, 0.3)',  // Gris claro con transparencia
    borderRadius: '8px',  // Bordes redondeados
    padding: '5px'        // Espacio entre el ícono y el borde
  }}
/>

        </div>



      </div>

    </div>


    {!mostrarResumen && (
  <>
    <div className="division2">

    <div className="division2-1">

      

      {selectedOption === 'option1' && <div className="division2-1">

          <div className="estilotitulo1">MATERIAL PARTICULADO [µg/m³]</div>

          <div className="boxes">

            <div className="box1">
              
              <div className="estilotitulo2">PM 1</div>

                {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
                {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPM1(equipo.PPM1).backgroundColor, color: getColorPM1(equipo.PPM1).color}} className="box2">{equipo.PPM1 !== null && equipo.PPM1 !== undefined ? equipo.PPM1 : "SIN DATOS"}</div></div>}

            </div>

            <div className="box1">
              
              <div className="estilotitulo2">PM 2.5</div>

                {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
                {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPM25(equipo.PPM25).backgroundColor, color: getColorPM25(equipo.PPM25).color}} className="box2">{equipo.PPM25 !== null && equipo.PPM25 !== undefined ? equipo.PPM25 : "SIN DATOS"}</div></div>}

            </div>

            <div className="box1">
              
              <div className="estilotitulo2">PM 10</div>
              
                {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black" }} className="box2">SELECCIONE EQUIPO</div></div>}
                {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPM10(equipo.PPM10).backgroundColor, color: getColorPM10(equipo.PPM10).color}} className="box2">{equipo.PPM10 !== null && equipo.PPM10 !== undefined ? equipo.PPM10 : "SIN DATOS"}</div></div>}
            
            </div>


          </div>
        
        </div>}

      {selectedOption === 'option2' && <div className="division2-1">

        <div className="estilotitulo1">GASES</div>

        <div className="boxes3">

          <div className="box4">
            
            <div className="estilotitulo4">{"CO2 (ppm)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}}  className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPCO2(equipo.PCO2).backgroundColor, color: getColorPCO2(equipo.PCO2).color}} className="box5">{equipo.PCO2 !== null && equipo.PCO2 !== undefined ? equipo.PCO2 : "SIN DATOS"}</div></div>}

          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"VOC"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPVOC(equipo.PVOC).backgroundColor, color: getColorPVOC(equipo.PVOC).color}} className="box5">{equipo.PVOC !== null && equipo.PVOC !== undefined ? equipo.PVOC : "SIN DATOS"}</div></div>}
            
          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"CH2O (mg/m³)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPCH2O(equipo.PCH2O).backgroundColor, color: getColorPCH2O(equipo.PCH2O).color}} className="box5">{equipo.PCH2O !== null && equipo.PCH2O !== undefined ? equipo.PCH2O : "SIN DATOS"}</div></div>}

          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"CO (ppm)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPCO(equipo.PCO).backgroundColor, color: getColorPCO(equipo.PCO).color}} className="box5">{equipo.PCO !== null && equipo.PCO !== undefined ? equipo.PCO : "SIN DATOS"}</div></div>}

          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"O3 (ppm)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPO3(equipo.PO3).backgroundColor, color: getColorPO3(equipo.PO3).color}} className="box5">{equipo.PO3 !== null && equipo.PO3 !== undefined ? equipo.PO3 : "SIN DATOS"}</div></div>}

          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"NO2 (ppm)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}}  className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPNO2(equipo.PNO2).backgroundColor, color: getColorPNO2(equipo.PNO2).color}} className="box5">{equipo.PNO2 !== null && equipo.PNO2 !== undefined ? equipo.PNO2 : "SIN DATOS"}</div></div>}

          </div>

          </div>
      
      </div>}

      {selectedOption === 'option3' && <div className="division2-1">

      <div className="estilotitulo1">AMBIENTE</div>

          <div className="boxes">

            <div className="box1">
              
              <div className="estilotitulo2">{"TEMPERATURA (°C)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{backgroundColor: getColorPTEMP(equipo.PTEMP).backgroundColor, color: getColorPTEMP(equipo.PTEMP).color}} className="box2">{equipo.PTEMP !== null && equipo.PTEMP !== undefined ? equipo.PTEMP : "SIN DATOS"}</div></div>}

            </div>

            <div className="box1">
              
              <div className="estilotitulo2">{"HUMEDAD (%RH)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPHUM(equipo.PHUM).backgroundColor, color: getColorPHUM(equipo.PHUM).color}} className="box2">{equipo.PHUM !== null && equipo.PHUM !== undefined ? equipo.PHUM : "SIN DATOS"}</div></div>}
              
            </div>

            <div className="box1">
              
              <div className="estilotitulo2">{"RUIDO (dBA)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPRUIDO(equipo.PRUIDO).backgroundColor, color: getColorPRUIDO(equipo.PRUIDO).color}} className="box2">{equipo.PRUIDO !== null && equipo.PRUIDO !== undefined ? equipo.PRUIDO : "SIN DATOS"}</div></div>}
              
            </div>

          </div>
      
      </div>}

    </div>

    
    <div className="division2-2">

    {selectedOption === 'option1' && <div className="division2-2">

          <div className="boxes2">

            <div className="box3">
              
              <div className="estilotitulo3">NIVEL</div>
              <div className="estilotitulo3" style={{ backgroundColor: "green", color: "white" }}>BAJO</div>
              <div className="estilotitulo3" style={{ backgroundColor: "yellow", color: "black" }}>MEDIO</div>
              <div className="estilotitulo3" style={{ backgroundColor: "red", color: "white" }}>ALTO</div>

            </div>

            <div className="box3">
              
              <div className="estilotitulo2">PM 1</div>
              <div className="estilotitulo2">{"< 10"}</div>
              <div className="estilotitulo2">{"10 ≤ X ≤ 25"}</div>
              <div className="estilotitulo2">{"> 25"}</div>
              
            </div>

            <div className="box3">
              
              <div className="estilotitulo2">PM 2.5</div>
              <div className="estilotitulo2">{"< 25"}</div>
              <div className="estilotitulo2">{"25 ≤ X ≤ 50"}</div>
              <div className="estilotitulo2">{"> 50"}</div>

            </div>

            <div className="box3">
              
              <div className="estilotitulo2">PM 10</div>
              <div className="estilotitulo2">{"< 50"}</div>
              <div className="estilotitulo2">{"50 ≤ X ≤ 100"}</div>
              <div className="estilotitulo2">{"> 100"}</div>

            </div>

          </div>
        
        </div>}

      {selectedOption === 'option2' && <div className="division2-1">
      
      <div className="boxes2">

      <div className="box3">
        
        <div className="estilotitulo3">NIVEL</div>
        <div className="estilotitulo3" style={{ backgroundColor: "green", color: "white" }}>BAJO</div>
        <div className="estilotitulo3" style={{ backgroundColor: "yellow", color: "black" }}>MEDIO</div>
        <div className="estilotitulo3" style={{ backgroundColor: "red", color: "white" }}>ALTO</div>

      </div>

      <div className="box3-1">

        <div className="estilotitulo2-1">CO2 </div>
        <div className="estilotitulo2-1">{"< 500"}</div>
        <div className="estilotitulo2-1">{"500 ≤ X ≤ 700"}</div>
        <div className="estilotitulo2-1">{"> 700"}</div>
        
      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">VOC</div>
        <div className="estilotitulo2-1">{"< 3.5"}</div>
        <div className="estilotitulo2-1">{"3.5 ≤ X ≤ 6"}</div>
        <div className="estilotitulo2-1">{"> 6"}</div>


      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">CH2O</div>
        <div className="estilotitulo2-1">{"< 1"}</div>
        <div className="estilotitulo2-1">{"1 ≤ X ≤ 3"}</div>
        <div className="estilotitulo2-1">{"> 3"}</div>

      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">CO</div>
        <div className="estilotitulo2-1">{"< 0.6"}</div>
        <div className="estilotitulo2-1">{"0.6 ≤ X ≤ 1"}</div>
        <div className="estilotitulo2-1">{"> 1"}</div>

      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">O3</div>
        <div className="estilotitulo2-1">{"< 0.1"}</div>
        <div className="estilotitulo2-1">{"0.1 ≤ X ≤ 0.2"}</div>
        <div className="estilotitulo2-1">{"> 0.2"}</div>

      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">NO2</div>
        <div className="estilotitulo2-1">{"< 0.15"}</div>
        <div className="estilotitulo2-1">{"0.15 ≤ X ≤ 2"}</div>
        <div className="estilotitulo2-1">{"> 2"}</div>

      </div>

      </div>

      </div>}

      {selectedOption === 'option3' && <div className="division2-1">
        
      <div className="boxes2">

        <div className="box3">
          
          <div className="estilotitulo3">NIVEL</div>
          <div className="estilotitulo3" style={{ backgroundColor: "green", color: "white" }}>BAJO</div>
          <div className="estilotitulo3" style={{ backgroundColor: "yellow", color: "black" }}>MEDIO</div>
          <div className="estilotitulo3" style={{ backgroundColor: "red", color: "white" }}>ALTO</div>

        </div>

        <div className="box3">
          
          <div className="estilotitulo2">TEMPERATURA</div>
          <div className="estilotitulo2">{"< 25"}</div>
          <div className="estilotitulo2">{"25 ≤ X ≤ 35"}</div>
          <div className="estilotitulo2">{"> 35"}</div>
          
        </div>

        <div className="box3">
          
          <div className="estilotitulo2">HUMEDAD</div>
          <div className="estilotitulo2">{"< 50"}</div>
          <div className="estilotitulo2">{"50 ≤ X ≤ 75"}</div>
          <div className="estilotitulo2">{"> 75"}</div>

        </div>

        <div className="box3">
          
          <div className="estilotitulo2">RUIDO</div>
          <div className="estilotitulo2">{"< 55"}</div>
          <div className="estilotitulo2">{"55 ≤ X ≤ 80"}</div>
          <div className="estilotitulo2">{"> 80"}</div>

        </div>

        </div>

        </div>}

    </div>
    
    </div>
    </>
)}


    <div className={`division3 ${mostrarResumen ? 'division3resumen' : ''}`}>




    <div>



  {mostrarResumen && (
    <div className="mosaicoResumen">
      <h3>Resumen de Equipos</h3>
      <div className="resumenEquipos-container">
  <input className="resumenEquipos-input" type="datetime-local" value={fechaInicio} onChange={handleFechaInicioChange} />
  <input className="resumenEquipos-input" type="datetime-local" value={fechaFin} onChange={handleFechaFinChange} />
  <label>
    <input className="resumenEquipos-checkbox" type="checkbox" checked={conPromedio} onChange={handlePromedioChange} />
    Incluir promedio
  </label>

  {/* Contenedor de los botones */}
  <div className="resumenEquipos-buttons">
    <button 
      className="resumenEquipos-button" 
      onClick={handleCSVDownload} 
      disabled={isDownloading} // Deshabilitar el botón mientras se está generando el CSV
    >
      {buttonText} {/* Cambia el texto dependiendo del estado */}
    </button>
   
  </div>
</div>
      {cargando ? (
        <div className="spinner"><HalfCircleSpinner/></div> // Indicador de carga
      ) : (
      <div className="gridMosaicos">
        {resumenEquipos.map((item, index) => (
          <div 
            key={index} 
            className={`mosaicoItem ${
              item.estatus === "En Linea" ? "enLinea" : 
              item.estatus.includes("Última vez") ? "ultimaVez" : 
              item.estatus === "Sin datos en Base" ? "sinDatos" : ""
            }`}
          >
            <div className="nombreEquipo">{item.nombreequipo ? item.nombreequipo.toUpperCase() : item.nombreequipo}</div>
            <div className="equipoID">Datalogger: {item.machinename ? item.machinename.toUpperCase() : item.machinename}</div>
            <div className="equipoID">Equipo ID: {item.equipo}</div>
            <div className="estatus">Estatus: {item.estatus}</div>
          </div>
        ))}
      </div>
    )}

{/* Footer con leyenda de colores */}
<footer className="leyendaColores">
  <ul className="leyendaLista">
    <li className="leyendaItem enLinea">Datos disponibles en los últimos 5 minutos.</li>
    <li className="leyendaItem ultimaVez">Sin datos durante más de 5 minutos.</li>
    <li className="leyendaItem sinDatos">No hay registros en la base de datos.</li>
  </ul>
</footer>
    </div>
  )}
</div>




    {(selectedOption === "option1" || selectedOption === "option2" || selectedOption === "option3") && selectedOption2 !== 'opcion 0' &&<div className="division3-0">

      <button
  type="button"
  className="boton-volver"
  onClick={() => {
    setSelectedOption(null);
    setSelectedOption2('opcion 0');
    setSelectedEquipo(null);
    setSelectedEquipoValue("")
    
  }}
>
  <img src={backk} alt="Volver" className="icono-flecha" />
</button>



          <select className="selectgraph" onChange={handleChange3}> 
            <option value="op1">Tiempo Real</option>
            <option value="op2">Histórico</option>
          </select>

          {selectedOption3 === 'op2' && <input className="selectgraphbutons" type="datetime-local" step="1" name="fechainicio" onChange={handleFechaInicioChange}></input>}
          {selectedOption3 === 'op2' && <input className="selectgraphbutons" type="datetime-local" step="1" name="fechafin" onChange={handleFechaFinChange}></input>}
          {selectedOption3 === 'op2' && <button type="button" className="botoncabina" onClick={enviar} >Enviar</button>}
          {selectedOption3 === 'op2' && enviado && !renderexcel &&<img src={excel} alt="boton excel" onClick={handleDownload} style={{ height:"32px", borderRadius: "100px"}} type="button"></img>}
          {selectedOption3 === 'op2' && enviado && renderexcel && <CirclesToRhombusesSpinner/>}
    </div>}

  

    {selectedOption2 !== 'opcion 0' && selectedOption === 'option1' && selectedOption3 === 'op1' &&<div className="division3-1"><MP equipo={selectedEquipo} tag={equipoexcel}/></div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option1' && selectedOption3 === 'op2' &&<div className="division3-1">{enviado && <MPM equipo={selectedEquipo} tag={equipoexcel} fechainicio={fechai} fechafin={fechaf}/>}</div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option2' && selectedOption3 === 'op1' &&<div className="division3-1"><G equipo={selectedEquipo} tag={equipoexcel}/></div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option2' && selectedOption3 === 'op2' &&<div className="division3-1">{enviado && <GM equipo={selectedEquipo} tag={equipoexcel} fechainicio={fechai} fechafin={fechaf}/>}</div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option3' && selectedOption3 === 'op1' &&<div className="division3-1"><A equipo={selectedEquipo} tag={equipoexcel}/></div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option3' && selectedOption3 === 'op2' &&<div className="division3-1">{enviado && <AM equipo={selectedEquipo} tag={equipoexcel} fechainicio={fechai} fechafin={fechaf}/>}</div>}

    </div>

  </div>

</div>


    );
}

export default ResumenCabina;