import React, { useRef, useEffect } from 'react';
//import React, { useEffect, useRef } from "react";
import './tablet.css';
import moment from 'moment';
import cerrar from './Imagenes/cerrar.png'
import Sidebar from './Componentes/sidebar'
import Banner from './Componentes/banner'
import Hls from "hls.js";

function Videos() {

  const heatMapRef = useRef(null);
  const normalViewRef = useRef(null);

  useEffect(() => {
    const setupPlayer = (url, videoRef) => {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(videoRef.current);
      } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        videoRef.current.src = url;
      }
    };

    // Reemplaza estas URLs con las direcciones HLS convertidas
    //const normalViewURL = "http://miningplus.nanomat.cl:8888/cam_botadero_visual/index.m3u8";
    //const heatMapURL = "http://miningplus.nanomat.cl:8888/cam_botadero_ir/index.m3u8";


    const normalViewURL = "https://camaras.mine-360.com/cam_botadero_visual/index.m3u8";
    const heatMapURL = "https://camaras.mine-360.com/cam_botadero_ir/index.m3u8";
//   https://camaras.mine-360.com
    setupPlayer(heatMapURL, heatMapRef);
    setupPlayer(normalViewURL, normalViewRef);
  }, []);

    
    return (
    
<div className="cuerpoimagenes">
<div style={{  textAlign: "center", fontFamily: "Arial, sans-serif", minHeight: "100vh" }}>


<Banner/>
<Sidebar/>
<div style={{ padding: "20px"}}>


  {/* Título principal */}
  <div style={{ display: "inline-block", backgroundColor: "#b0b0b0", padding: "15px 30px", borderRadius: "10px", marginBottom: "20px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}>
    <h1 style={{ color: "#333", margin: 0, fontSize: "24px", fontWeight: "bold" }}>CÁMARAS BOTADERO</h1>
  </div>

  {/* Contenedor de videos */}
  <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", gap: "30px", padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
    {/* Visión de Calor */}
    <div>
      <div style={{ display: "inline-block", backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "10px 20px", borderRadius: "10px", marginBottom: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}>
        <h2 style={{ color: "#fff", margin: 0, fontSize: "18px" }}>Visión de Calor</h2>
      </div>
      <video ref={heatMapRef} controls autoPlay style={{ width: "100%", borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }} />
    </div>

    {/* Vista Normal */}
    <div>
      <div style={{ display: "inline-block", backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "10px 20px", borderRadius: "10px", marginBottom: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}>
        <h2 style={{ color: "#fff", margin: 0, fontSize: "18px" }}>Vista Normal</h2>
      </div>
      <video ref={normalViewRef} controls autoPlay style={{ width: "100%", borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }} />
    </div>
  </div>
</div>

</div>
</div>
    
    );
  }
  
  export default Videos;