import React, { useState, useEffect } from 'react';
import './tablet.css';
import moment from 'moment';
import cerrar from './Imagenes/cerrar.png'
import Sidebar from './Componentes/sidebar'
import Banner from './Componentes/banner'

function Imagenes() {

    const [imagenAmpliada, setImagenAmpliada] = useState(null);
    const [imagenAmpliadaDatos, setImagenAmpliadaDatos] = useState(null);
    const [filtro, setFiltro] = useState(null);

    const handleClick = (imagen) => {
      const imagenSeleccionada = imagenes.find(item => `/imagenes/screen-${item.id_maquina}.jpg?timestamp=${new Date().getTime()}` === imagen)
      setImagenAmpliada(imagen === imagenAmpliada ? null : imagen);
      setImagenAmpliadaDatos(imagenSeleccionada);
  }

    const handleFiltroClick = (id) => {
        const selectedClass = "redLine";
        const h1Elements = document.querySelectorAll('h1');
        h1Elements.forEach((element) => {
            if (element.classList.contains(selectedClass)) {
                // remove the class if it already exists
                element.classList.remove(selectedClass);
            }
        });
    
        if (id === 'TODOS') {
            setFiltro(null);
            const selectedH1 = document.querySelector(`h1[data-id="${id}"]`);
            selectedH1.classList.add(selectedClass); // add red line
        } else {
            setFiltro(id);
            const selectedH1 = document.querySelector(`h1[data-id="${id}"]`);
            selectedH1.classList.add(selectedClass); // add red line
        }
    }

    const [imagenes, setImagenes] = useState([]);
    const [imagenes1, setImagenes1] = useState([]);
    const [imagenes2, setImagenes2] = useState([]);
    const [imagenes3, setImagenes3] = useState([]);
    const [imagenes4, setImagenes4] = useState([]);
    const [imagenes5, setImagenes5] = useState([]);
    const [imagenes6, setImagenes6] = useState([]);
    const url_base = 'https://www.mine-360.com/api/archivos/getimages';
    
    const fetchData = async () => {
      try {
        const response = await fetch(url_base, { 
          method: "GET",
        });
        const data = await response.json();

        const imagenes = data.filter(item => item.faena !== "TALLER");
        setImagenes(imagenes);

        const imagenesCAN = data.filter(item => item.faena === "CANDELARIA");
        setImagenes1(imagenesCAN);

        const imagenesANT = data.filter(item => item.faena === "ANTUCOYA");
        setImagenes2(imagenesANT);

        const imagenesDMH = data.filter(item => item.faena === "DMH");
        setImagenes3(imagenesDMH);

        const imagenesDRT = data.filter(item => item.faena === "DRT");
        setImagenes4(imagenesDRT);

        const imagenesCEN = data.filter(item => item.faena === "CENTINELA");
        setImagenes5(imagenesCEN);

        const imagenesTALL = data.filter(item => item.faena === "TALLER");
        setImagenes6(imagenesTALL);
    
      } catch (error) {
        console.error(error);
      }
    };
    
    useEffect(() => {
        fetchData();
      
        const interval = setInterval(() => {
          fetchData();
        }, 5000); // Cambiar el intervalo a 5000ms
      
        return () => {
          clearInterval(interval);
        };
      }, []);

      const getMinutesDifference = (dateString) => {
        const fecha = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
        const horaActual = moment();
    
        const diff = horaActual.diff(fecha, 'minutes');
        return diff;
    }

    const getFormattedDate = (dateString) => {
      const date = new Date(dateString);
      date.setHours(date.getHours() + 4); // Añadir 4 horas al objeto Date
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
  
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const Home = () => {

    window.location.href = "/inicio";
  
  };


    const regadores = {
      'tablet-061':'REGADOR 1',
      'tablet-060':'REGADOR 3',
      'tablet-057':'REGADOR 501',
      'tablet-048':'REGADOR 502',
      'tablet-108':'REGADOR 503',
      'tablet-104':'REGADOR 504',
      'tablet-109':'REGADOR 2',
      'tablet-020':'REGADOR 15',
      'tablet-044':'REGADOR 13',
      'tablet-042':'REGADOR 14',
      'tablet-050':'REGADOR 7',
      'tablet-056':'REGADOR 2',
      'tablet-031':'REGADOR 16',
      'tablet-053':'REGADOR 8',
      'tablet-054':'REGADOR 581',
      'tablet-058':'REGADOR 507',
      'tablet-106':'REGADOR 505',
      'tablet-107':'REGADOR 20359',

      // Agrega más nombres de imagen y regadores según sea necesario
    };

    //CAN ANT DHM DRT CEN

    function ordenarPorRegador(imagenes, regador) {
      return imagenes
        .filter(imagen => regador[imagen.nombre_tablet]) // Filtrar solo las imágenes con regador asignado
        .sort((a, b) => {
          const regadorA = regador[a.nombre_tablet] ? regador[a.nombre_tablet].replace('REGADOR ', '') : '';
          const regadorB = regador[b.nombre_tablet] ? regador[b.nombre_tablet].replace('REGADOR ', '') : '';
          return regadorA - regadorB;
        });
    }
    
    return (
    
<div className="cuerpoimagenes">

<Banner/>
<Sidebar/>

{imagenAmpliadaDatos && (
    
      <div className="imagenAmpliada" onClick={() => handleClick(imagenAmpliada)}>
          <img alt="consola" src={imagenAmpliada}></img>
          <h1>{imagenAmpliadaDatos.faena} {regadores[imagenAmpliadaDatos.nombre_tablet]} Fecha y hora ult. act: {getFormattedDate(imagenAmpliadaDatos.ult_recepc_foto)}</h1>
      </div>

)}

<img src={cerrar} id="cerrartablet" alt="regresar" height="40px" width="40px" onClick={Home}></img>

    <div className="filtrotablet">

        <h1 data-id="TODOS" className="redLine" onClick={() => handleFiltroClick('TODOS')}>TODOS</h1>
        <h1 data-id="CAN" onClick={() => handleFiltroClick('CAN')}>CANDELARIA</h1>
        <h1 data-id="ANT" onClick={() => handleFiltroClick('ANT')}>ANTUCOYA</h1>
        <h1 data-id="DMH" onClick={() => handleFiltroClick('DMH')}>DMH</h1>
        <h1 data-id="DRT" onClick={() => handleFiltroClick('DRT')}>DRT</h1>
        <h1 data-id="CEN" onClick={() => handleFiltroClick('CEN')}>CENTINELA</h1>
        <h1 data-id="TALL" onClick={() => handleFiltroClick('TALL')}>TALLER</h1>

    </div>

    <div className="q4x5grid">
        
        <div className="q4x5">

        {(filtro === null) && <div style={{ display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
          {ordenarPorRegador(imagenes, regadores).map(imagen => (
            <div key={imagen.idimagen} id={imagen.faena} className="item">
              <h1 style={{ textAlign: 'left', marginLeft: '5px' }}>{imagen.faena}
              <span style={{ float: 'right', marginRight: '5px' }}>{regadores[imagen.nombre_tablet]}</span>
              </h1>
              <img onClick={() => handleClick(`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`)} alt="consola" src={`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`}></img>
              <h1 style={{ textAlign: 'right', marginRight: '5px', color: getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 'red' : 'black' }}><span style={{ float: 'left', marginLeft: '5px' }}>{imagen.nombre_tablet}</span>
              {getMinutesDifference(imagen.ult_recepc_foto) === 0 ? 'Justo ahora' : 
              getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 
              `Hace ${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60)} hora${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60) === 1 ? '' : 's'} ${getMinutesDifference(imagen.ult_recepc_foto) % 60} minutos` : 
              `Hace ${getMinutesDifference(imagen.ult_recepc_foto)} minutos`}
              </h1>
            </div>
              ))}
        </div>}

        {(filtro === 'CAN') && <div style={{ display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
          {ordenarPorRegador(imagenes1, regadores).map(imagen => (
            <div key={imagen.idimagen} id={imagen.faena} className="item">
              <h1 style={{ textAlign: 'left', marginLeft: '5px' }}>{imagen.faena}
              <span style={{ float: 'right', marginRight: '5px' }}>{regadores[imagen.nombre_tablet]}</span>
              </h1>
              <img onClick={() => handleClick(`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`)} alt="consola" src={`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`}></img>
              <h1 style={{ textAlign: 'right', marginRight: '5px', color: getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 'red' : 'black' }}>  
              <span style={{ float: 'left', marginLeft: '5px' }}>{imagen.nombre_tablet}</span>
              {getMinutesDifference(imagen.ult_recepc_foto) === 0 ? 'Justo ahora' : 
              getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 
              `Hace ${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60)} hora${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60) === 1 ? '' : 's'} ${getMinutesDifference(imagen.ult_recepc_foto) % 60} minutos` : 
              `Hace ${getMinutesDifference(imagen.ult_recepc_foto)} minutos`}
              </h1>
            </div>
              ))}
        </div>}

        {(filtro === 'ANT') && <div style={{ display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
          {ordenarPorRegador(imagenes2, regadores).map(imagen => (
            <div key={imagen.idimagen} id={imagen.faena} className="item">
              <h1 style={{ textAlign: 'left', marginLeft: '5px' }}>{imagen.faena}
              <span style={{ float: 'right', marginRight: '5px' }}>{regadores[imagen.nombre_tablet]}</span>
              </h1>
              <img onClick={() => handleClick(`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`)} alt="consola" src={`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`}></img>
              <h1 style={{ textAlign: 'right', marginRight: '5px', color: getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 'red' : 'black' }}>
              <span style={{ float: 'left', marginLeft: '5px' }}>{imagen.nombre_tablet}</span>
              {getMinutesDifference(imagen.ult_recepc_foto) === 0 ? 'Justo ahora' : 
              getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 
              `Hace ${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60)} hora${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60) === 1 ? '' : 's'} ${getMinutesDifference(imagen.ult_recepc_foto) % 60} minutos` : 
              `Hace ${getMinutesDifference(imagen.ult_recepc_foto)} minutos`}
              </h1>
            </div>
              ))}
        </div>}

        {(filtro === 'DMH') && <div style={{ display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
          {ordenarPorRegador(imagenes3, regadores).map(imagen => (
            <div key={imagen.idimagen} id={imagen.faena} className="item">
              <h1 style={{ textAlign: 'left', marginLeft: '5px' }}>{imagen.faena}
              <span style={{ float: 'right', marginRight: '5px' }}>{regadores[imagen.nombre_tablet]}</span>
              </h1>
              <img onClick={() => handleClick(`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`)} alt="consola" src={`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`}></img>
              <h1 style={{ textAlign: 'right', marginRight: '5px', color: getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 'red' : 'black' }}>  
              <span style={{ float: 'left', marginLeft: '5px' }}>{imagen.nombre_tablet}</span>
              {getMinutesDifference(imagen.ult_recepc_foto) === 0 ? 'Justo ahora' : 
              getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 
              `Hace ${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60)} hora${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60) === 1 ? '' : 's'} ${getMinutesDifference(imagen.ult_recepc_foto) % 60} minutos` : 
              `Hace ${getMinutesDifference(imagen.ult_recepc_foto)} minutos`}
              </h1>
            </div>
              ))}
        </div>}

        {(filtro === 'DRT') && <div style={{ display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
          {ordenarPorRegador(imagenes4, regadores).map(imagen => (
            <div key={imagen.idimagen} id={imagen.faena} className="item">
              <h1 style={{ textAlign: 'left', marginLeft: '5px' }}>{imagen.faena}
              <span style={{ float: 'right', marginRight: '5px' }}>{regadores[imagen.nombre_tablet]}</span>
              </h1>
              <img onClick={() => handleClick(`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`)} alt="consola" src={`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`}></img>
              <h1 style={{ textAlign: 'right', marginRight: '5px', color: getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 'red' : 'black' }}>  
              <span style={{ float: 'left', marginLeft: '5px' }}>{imagen.nombre_tablet}</span>
              {getMinutesDifference(imagen.ult_recepc_foto) === 0 ? 'Justo ahora' : 
              getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 
              `Hace ${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60)} hora${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60) === 1 ? '' : 's'} ${getMinutesDifference(imagen.ult_recepc_foto) % 60} minutos` : 
              `Hace ${getMinutesDifference(imagen.ult_recepc_foto)} minutos`}
              </h1>
            </div>
              ))}
        </div>}

        {(filtro === 'CEN') && <div style={{ display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
          {ordenarPorRegador(imagenes5, regadores).map(imagen => (
            <div key={imagen.idimagen} id={imagen.faena} className="item">
              <h1 style={{ textAlign: 'left', marginLeft: '5px' }}>{imagen.faena}
              <span style={{ float: 'right', marginRight: '5px' }}>{regadores[imagen.nombre_tablet]}</span>
              </h1>
              <img onClick={() => handleClick(`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`)} alt="consola" src={`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`}></img>
              <h1 style={{ textAlign: 'right', marginRight: '5px', color: getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 'red' : 'black' }}>  
              <span style={{ float: 'left', marginLeft: '5px' }}>{imagen.nombre_tablet}</span>
              {getMinutesDifference(imagen.ult_recepc_foto) === 0 ? 'Justo ahora' : 
              getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 
              `Hace ${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60)} hora${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60) === 1 ? '' : 's'} ${getMinutesDifference(imagen.ult_recepc_foto) % 60} minutos` : 
              `Hace ${getMinutesDifference(imagen.ult_recepc_foto)} minutos`}
              </h1>
            </div>
              ))}
        </div>}

        {(filtro === 'TALL') && <div style={{ display: 'flex', flexWrap: 'wrap', placeContent: 'center' }}>
          {ordenarPorRegador(imagenes6, regadores).map(imagen => (
            <div key={imagen.idimagen} id={imagen.faena} className="item">
              <h1 style={{ textAlign: 'left', marginLeft: '5px' }}>{imagen.faena}
              <span style={{ float: 'right', marginRight: '5px' }}>{regadores[imagen.nombre_tablet]}</span>
              </h1>
              <img onClick={() => handleClick(`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`)} alt="consola" src={`/imagenes/screen-${imagen.id_maquina}.jpg?timestamp=${new Date().getTime()}`}></img>
              <h1 style={{ textAlign: 'right', marginRight: '5px', color: getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 'red' : 'black' }}>  
              <span style={{ float: 'left', marginLeft: '5px' }}>{imagen.nombre_tablet}</span>
              {getMinutesDifference(imagen.ult_recepc_foto) === 0 ? 'Justo ahora' : 
              getMinutesDifference(imagen.ult_recepc_foto) >= 60 ? 
              `Hace ${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60)} hora${Math.floor(getMinutesDifference(imagen.ult_recepc_foto) / 60) === 1 ? '' : 's'} ${getMinutesDifference(imagen.ult_recepc_foto) % 60} minutos` : 
              `Hace ${getMinutesDifference(imagen.ult_recepc_foto)} minutos`}
              </h1>
            </div>
              ))}
        </div>}


        </div>

    </div>

</div>
    
    );
  }
  
  export default Imagenes;